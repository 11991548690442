const script = document.createElement('script')
script.src = '//eu.cookie-script.com/s/015ddf843f6501237a7e065ab8ebd81f.js'
document.body.appendChild(script)
script.onload = () => {
    if (window.CookieScript) {
        const cookieScriptInstance = window.CookieScript.instance
        if (cookieScriptInstance){
            const currentState = cookieScriptInstance.currentState()
            if (currentState && !currentState.action){
                cookieScriptInstance.acceptAllAction()
            }
        }
    }
}
